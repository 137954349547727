import React, { useState } from 'react';
import './LossesCalculator.css';

const LossesCalculator: React.FC = () => {
  const [numberOfEmployees, setNumberOfEmployees] = useState<number>(0);
  const [averageUnloadTime, setAverageUnloadTime] = useState<number>(0);
  const [averageWages, setAverageWages] = useState<number>(0);
  const [potentialSavings, setPotentialSavings] = useState<number>(0);

  const handleNumberOfEmployeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfEmployees(parseInt(event.target.value));
  };

  const handleAverageUnloadTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAverageUnloadTime(parseFloat(event.target.value));
  };

  const handleAverageWagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAverageWages(parseFloat(event.target.value));
  };

  const calculatePotentialSavings = () => {
    const averageCost = numberOfEmployees * averageUnloadTime * averageWages;
    const hardCost = (numberOfEmployees - 1) * averageUnloadTime * averageWages;
    const potentialSavings = averageCost - hardCost;
    setPotentialSavings(potentialSavings);
  };

  return (
    <div className="losses-calculator"> {/* Add the CSS class here */}
        <h2 className="section-title">Calculate Your Losses</h2> {/* Add a unique class */}
      <div>
        <label>Number of Employees:</label>
        <input type="number" value={numberOfEmployees} onChange={handleNumberOfEmployeesChange} />
      </div>
      <div>
        <label>Average Unload Hours:</label>
        <input type="number" value={averageUnloadTime} onChange={handleAverageUnloadTimeChange} />
      </div>
      <div>
        <label>Average Wages:</label>
        <input type="number" value={averageWages} onChange={handleAverageWagesChange} />
      </div>
      <button onClick={calculatePotentialSavings}>Calculate Potential Savings</button>
      <div>
        <h3>Potential Savings: ${potentialSavings}</h3>
      </div>
    </div>
  );
};

export default LossesCalculator;
