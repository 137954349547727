/**
 * LoginPage.tsx
 * 
 * This is the login page for the application, responsible for presenting users with the login interface.
 * It utilizes the LoginModal component to handle the authentication process and uses Ionic components
 * for basic page structure. Upon successful authentication, the user is redirected to a specified page
 * within the application (Tab1 in this case).
 * 
 * Dependencies:
 * - React: For defining the component and using the useEffect hook.
 * - Ionic React Components: For the UI structure of the page (IonPage, IonHeader, IonContent, etc.).
 * - LoginModal: A custom component that provides the actual login modal with various authentication methods.
 * - UserManager: Custom hook `useAuthUser` to access the authenticated user's details for redirect logic.
 * 
 * Behavior:
 * - On component mount, the useEffect hook checks if there's an authenticated user. If so, it redirects to the
 *   main content of the app (Tab1), effectively bypassing the login screen for already authenticated users.
 * - The LoginModal component is displayed to unauthenticated users, providing options for login.
 */
// LoginPage.tsx

// LoginPage.tsx
// LoginPage.tsx
import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonText,
  useIonRouter,
  IonIcon,
  IonButton // Import IonButton
} from '@ionic/react';
import { barcodeSharp } from 'ionicons/icons';
import LoginModal from '../../components/LoginModal';
import { useAuthUser } from '../../components/UserManager';
import './LoginPage.css';

const HeaderLogo: React.FC = () => {
  return <a className="header-logo" href="/home"><IonIcon icon={barcodeSharp}></IonIcon>Cabinet Scanner</a>;
};

const LoginPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Control the visibility of the modal
  const router = useIonRouter();
  const { authUser } = useAuthUser();

  useEffect(() => {
    if (authUser) {
      router.push('/tab1', 'forward', 'replace');
    }
  }, [authUser, router]);

  const handleLoginSuccess = () => {
    setIsModalOpen(false);
    router.push('/tab1', 'forward', 'replace');
  };

  const openLoginModal = () => {
    setIsModalOpen(true); // Function to open the login modal
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HeaderLogo />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonText>
          <p style={{ textAlign: 'center', padding: '20px' }}>
            Please log in to access the app. For information, please visit the desktop site as our mobile landing page is currently under development.
          </p>
        </IonText>
        {/* Button to open the login modal */}
        <div style={{ textAlign: 'center' }}>
          <IonButton onClick={openLoginModal}>Login</IonButton>
        </div>
        <LoginModal onLoginSuccess={handleLoginSuccess} showModal={isModalOpen} setShowModal={setIsModalOpen} />
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
