// DesktopHomepage.tsx
import React, { useState } from 'react';
import Header from './Header';
import './DesktopHomepage.css';
import screenshot1 from '../../../../public/assets/Screenshot1.png';
import screenshot2 from '../../../../public/assets/Screenshot2.png';
import screenshot3 from '../../../../public/assets/Screenshot3.png';
import SignUpPage from '../../../pages/SignUpPage';
import LossesCalculator from './LossesCalculator'; // Import the LossesCalculator component


const DesktopHomepage: React.FC = () => {
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    return (
        <div className="desktop-homepage">
            <Header />
            <div className="frame">
                <div className="eliminate-the-paper">
                    <div className="div">
                        <div className="eliminate-the-paper-wrapper">
                            <p className="p">
                                <span className="text-wrapper">Eliminate the Paper Hassle<br /></span>
                                <span className="span">By Scanning and Logging Cabinets with Ease!</span>
                            </p>
                        </div>
                        <div className="NO-more-clipboards-wrapper">
                            <p className="NO-more-clipboards">
                                <span className="text-wrapper-2">NO</span>
                                <span className="text-wrapper-3">&nbsp;</span>
                                <span className="text-wrapper-4">more clipboards<br /></span>
                                <span className="text-wrapper-2">NO</span>
                                <span className="text-wrapper-3">&nbsp;</span>
                                <span className="text-wrapper-4">searching through endless inventory list<br /></span>
                                <span className="text-wrapper-2">NO</span>
                                <span className="text-wrapper-3">&nbsp;</span>
                                <span className="text-wrapper-4">lost cabinets</span>
                            </p>
                        </div>
                        <div className="div-2">
                            <div className="frame-wrapper">
                                <div className="div-wrapper">
                                    <button className="trial-button" onClick={() => setShowSignUpModal(true)}>Start your FREE trial NOW!</button>
                                </div>
                            </div>
                            <div className="div-wrapper-2">
                                <p className="text-wrapper-6">Custom made for cabinet dealers</p>
                            </div>
                        </div>
                    </div>
                    <div className="phone-frame">
                        <div className="iphone-pro-max">
                            <img className="image" alt="Feature demonstration" src={screenshot1} />
                        </div>
                    </div>
                </div>

                <div className="div-3">
                    <div className="iphone-pro-max-wrapper">
                        <div className="image-wrapper">
                            <img className="img" alt="Goodbye to Searching" src={screenshot2} />
                        </div>
                    </div>
                    <div className="div-4">
                        <div className="div-5">
                            <p className="say-goodbye-to">
                                <span className="text-wrapper">Say Goodbye to Searching, <br /></span>
                                <span className="text-wrapper-7">Say Hello to Freedom</span>
                            </p>
                        </div>
                        <div className="div-6">
                            <p className="text-wrapper-8">
                                Ever reached the end of a delivery, only to find an unmarked cabinet? The tedious task of rummaging
                                through piles of cabinets and parts is over. Welcome to the new era of precision and efficiency.
                            </p>
                        </div>
                        <div className="effortless-scanning-wrapper">
                            <div className="effortless-scanning">
                                Effortless Scanning
                                <br />
                                Friction-Less Logging
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div-7">
                    <div className="div-8">
                        <div className="beyond-checklists-wrapper">
                            <p className="beyond-checklists">
                                <span className="text-wrapper">Beyond Checklists: </span>
                                <span className="span">
                                    Organize Your Inventory with a Full-Feature Database
                                    <br />
                                </span>
                            </p>
                        </div>
                        <div className="div-wrapper-3">
                            <p className="text-wrapper-9">
                                Check them into a warehouse, a home, or a custom location - the possibilities are endless
                            </p>
                        </div>
                        <div className="div-9">
                            <div className="frame-wrapper-2">
                                <div className="div-wrapper-4">
                                    <button className="trial-button" onClick={() => setShowSignUpModal(true)}>Start your FREE trial NOW!</button>
                                </div>
                            </div>
                            <div className="frame-wrapper-3">
                                <div className="group-wrapper">
                                    <div className="group-2">
                                        <div className="text-wrapper-11">Cloud-Based Management Portal</div>
                                        <p className="text-wrapper-12">
                                            The cloud-based barcode system centrally manages app users, configures data, and organizes storage.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="phone-frame-2">
                        <div className="img-wrapper">
                            <img className="image" alt="Inventory organization" src={screenshot3} />
                        </div>
                    </div>
                </div>

                <div className="div-10">
    <p className="labor-costs-suck-cut">
        <span className="text-wrapper">
            Labor Costs Suck, <br />
        </span>
        <span className="span">
            Cut Them with Cabinet Scanner
            <br />
        </span>
    </p>
    <LossesCalculator />
    <div className="savings-button-wrapper">
        <button className="trial-button" onClick={() => setShowSignUpModal(true)}>Start Saving Today!</button>
    </div>
</div>

            </div>

            {/* Including the SignUpPage modal */}
            <SignUpPage isOpen={showSignUpModal} onClose={() => setShowSignUpModal(false)} />
        </div>
    );
};

export default DesktopHomepage;
