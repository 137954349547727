import React, { useState } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonToolbar, IonTitle, IonInput, IonToast } from '@ionic/react';
import { doc, getFirestore, updateDoc, arrayUnion } from 'firebase/firestore';
import { useUserData } from './UserDataFetcher'; // Import the custom hook to access user data

interface AddAuthorizedEmailProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddAuthorizedEmail: React.FC<AddAuthorizedEmailProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const userData = useUserData(); // Use the custom hook to get user data

  const handleAuthorizeEmail = async () => {
    setIsLoading(true);
    setIsError(false);
    const db = getFirestore();

    // Use the dealer ID from the user data
    const dealerId = userData?.dealerIds; // Assuming dealerIds contains the correct dealer ID

    if (!dealerId) {
      console.error("No dealer ID found for the current user.");
      setIsLoading(false);
      setIsError(true);
      setToastMessage('Failed to authorize email: No dealer ID available.');
      setShowToast(true);
      return;
    }

    try {
      const dealerDocRef = doc(db, 'dealers', dealerId);
      await updateDoc(dealerDocRef, {
        authorizedEmails: arrayUnion(email)
      });

      setToastMessage('Email authorized successfully!');
      setShowToast(true);
      setIsLoading(false);
      setTimeout(onClose, 2000);
    } catch (error) {
      console.error('Error authorizing email:', error);
      setIsLoading(false);
      setIsError(true);
      setToastMessage('Error authorizing email: ' + (error instanceof Error ? error.message : 'Unknown error'));
      setShowToast(true);
    }
  };

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={onClose}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Authorize Email</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {isLoading ? (
            <div>Authorizing Email...</div>
          ) : (
            <>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
                placeholder="Email"
                required
              ></IonInput>
              <IonButton expand="block" onClick={handleAuthorizeEmail}>Authorize Email</IonButton>
            </>
          )}
        </IonContent>
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={isError ? 'danger' : 'success'}
      />
    </>
  );
};

export default AddAuthorizedEmail;
