import React, { useState, useEffect, useRef } from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from '@ionic/react';
import './SignUpPage.css';
import { useSignUp } from './SignUp/UserSignUp'; // Adjust path as necessary
import AccountOwnerSignUp from './SignUp/AccountOwnerSignUp'; // Adjust path as necessary
import CustomManufacturerPopUp from './SignUp/CustomManufacturerPopUp';
import UserTypePopUp from './SignUp/UserTypePopUp';

interface SignUpPageProps {
  isOpen: boolean;
  onClose: () => void;
}

const SignUpPage: React.FC<SignUpPageProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [userType, setUserType] = useState('');
  const [dealerId, setDealerId] = useState('');
  const [showCustomManufacturer, setShowCustomManufacturer] = useState(false);
  const [customManufacturer, setCustomManufacturer] = useState('');
  const [showUserTypePopUp, setShowUserTypePopUp] = useState(false);
  const [showAccountOwnerSignUp, setShowAccountOwnerSignUp] = useState(false);
  const modalRef = useRef<HTMLIonModalElement>(null);

  const { signUpUser, isLoading } = useSignUp(() => {
    if (userType === 'User') {
      onClose();
    }
  });

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.offsetHeight; // Trigger reflow
    }
  }, [isOpen]);

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    if (userType === 'Account Owner' && !dealerId) {
      alert("Dealer ID is required for account owners.");
      return;
    }

    if (manufacturer === 'Other') {
      setShowCustomManufacturer(true);
      return;
    }

    if (!userType) {
      alert("Please select your user type.");
      return;
    }

    if (userType === 'User') {
      signUpUser({ email, password, manufacturer });
    } else if (userType === 'Account Owner') {
      setShowAccountOwnerSignUp(true);
    }
  };

  return (
    <>
      <IonModal isOpen={isOpen} onDidDismiss={onClose} ref={modalRef}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Sign Up</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form onSubmit={handleSignUp}>
            {/* Form fields */}
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput value={email} onIonChange={e => setEmail(e.detail.value!)} type="email" required />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput value={password} onIonChange={e => setPassword(e.detail.value!)} type="password" required />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Confirm Password</IonLabel>
              <IonInput value={confirmPassword} onIonChange={e => setConfirmPassword(e.detail.value!)} type="password" required />
            </IonItem>
            <IonItem>
              <IonLabel>Cabinet Manufacturer</IonLabel>
              <IonSelect value={manufacturer} onIonChange={e => setManufacturer(e.detail.value)} placeholder="Select One">
                <IonSelectOption value="Wood-Mode">Wood-Mode</IonSelectOption>
                <IonSelectOption value="Other">Other</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>User Type</IonLabel>
              <IonSelect value={userType} onIonChange={e => setUserType(e.detail.value)} placeholder="Select One">
                <IonSelectOption value="Account Owner">Account Owner</IonSelectOption>
                <IonSelectOption value="User">User</IonSelectOption>
              </IonSelect>
            </IonItem>
            {userType === 'Account Owner' && (
              <IonItem>
                <IonLabel position="floating">Dealer ID</IonLabel>
                <IonInput value={dealerId} onIonChange={e => setDealerId(e.detail.value!)} type="text" required />
              </IonItem>
            )}
            <IonButton className="unique-signup-btn" expand="block" type="submit" disabled={isLoading}>
              {isLoading ? 'Processing...' : 'Sign Up'}
            </IonButton>
          </form>
        </IonContent>
      </IonModal>
  
      {showCustomManufacturer && (
        <CustomManufacturerPopUp
          isOpen={showCustomManufacturer}
          onClose={() => setShowCustomManufacturer(false)}
          userData={{ email, password, dealerId, customManufacturer, userType }}
        />
      )}
      {showUserTypePopUp && (
        <UserTypePopUp
          isOpen={showUserTypePopUp}
          onClose={() => setShowUserTypePopUp(false)}
          userData={{ email, password, dealerId, manufacturer, customManufacturer, userType }}
        />
      )}
      {showAccountOwnerSignUp && (
  <AccountOwnerSignUp
    isOpen={showAccountOwnerSignUp}
    onClose={() => setShowAccountOwnerSignUp(false)}
    email={email}
    password={password}
    dealerId={dealerId}
    manufacturer={manufacturer === 'Other' ? customManufacturer : manufacturer}
  />
)}
    </>
  );
  };
  
  export default SignUpPage;
  