import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStripePayments } from '@stripe/firestore-stripe-payments';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCXjLWw7XwOQqeyc7F59MQYHyx9fmoHgxI",
  authDomain: "cabinet-logger.firebaseapp.com",
  projectId: "cabinet-logger",
  storageBucket: "cabinet-logger.appspot.com",
  messagingSenderId: "281767009870",
  appId: "1:281767009870:web:a5cbcc33765dcfbe7f2d1f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);

// Initialize Stripe Payments with specific collections
const payments = getStripePayments(app, {
  customersCollection: 'users', // Using 'users' as the customer collection
  productsCollection: 'products', // Your manually created 'products' collection
});

export { db, auth, payments, app, };

