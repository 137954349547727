import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { auth } from '../../firebaseConfig';
import { getCheckoutUrl } from '../../components/stripeService'; // Ensure this path is correct

interface AccountOwnerSignUpProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  password: string;
  dealerId: string;
  manufacturer: string;
}

const AccountOwnerSignUp: React.FC<AccountOwnerSignUpProps> = ({
  isOpen,
  onClose,
  email,
  password,
  dealerId,
  manufacturer,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const handleSignUpAndNavigateToStripe = async () => {
      if (!isOpen) return;

      // Early return if dealerId is not provided
      if (!dealerId.trim()) {
        setError('Dealer ID is required.');
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(''); // Reset error state
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        console.log(`[AccountOwnerSignUp] User signed up with UID: ${userCredential.user.uid}`);
        // Assuming the priceId is static or determined elsewhere in your application
        const priceId = 'price_1OqMi7AEPjl4X7Nrbn2gjYfg';
        const checkoutUrl = await getCheckoutUrl(auth.app, priceId, dealerId, manufacturer, "Account Owner");
        window.location.href = checkoutUrl;
      } catch (error) {
        console.error("[AccountOwnerSignUp] Error during sign up or Stripe navigation:", error);
        setError('An error occurred during the sign-up process. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    handleSignUpAndNavigateToStripe();
  }, [isOpen, email, password, dealerId, manufacturer]); // Re-run this effect if any of these props change

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account Owner Sign Up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {isLoading ? <div>Processing...</div> : <div>Please wait...</div>}
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </IonContent>
    </IonModal>
  );
};

export default AccountOwnerSignUp;
