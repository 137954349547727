import { db } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

// Define the structure of the data to be uploaded, including jobName and estimatedDeliveryDate
type UploadData = {
  orderNumber: string;
  itemNumber: string;
  quantity: string;
  description: string;
  scanStatus: string;
  uploadedBy: string;
  cabinetLineId: string;
  dealerId: string;
  jobName: string; // Ensure jobName is part of the structure
  estimatedDeliveryDate: string; // Ensure estimatedDeliveryDate is part of the structure
};

export const uploadToFirestore = async (
  collectionName: string, 
  docName: string, 
  data: UploadData
): Promise<boolean> => {
  // No need for additional handling for jobName and estimatedDeliveryDate here since they're part of UploadData
  if (!docName) return false;

  console.log(`Attempting to write to collection: ${collectionName}, Document: ${docName}`);
  console.log('Data:', data);

  const docRef = doc(db, collectionName, docName);
  try {
    await setDoc(docRef, data); // Directly upload the data including jobName and estimatedDeliveryDate
    console.log("Document successfully written!");
    return true;  // Indicates success
  } catch (e) {
    console.error("Error writing document: ", e);
    return false;  // Indicates failure
  }
};
