import React, { useState } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonList, IonItem, IonLabel, IonModal } from '@ionic/react';
import { useAuthUser } from '../../components/UserManager';
import { useUserData } from '../../components/UserDataFetcher';
import AddUserModel from '../../components/AddAuthorizedEmail'; // Ensure the path to AddUserModal is correct
import './Settings.css';

const Settings: React.FC = () => {
  const [developerMode, setDeveloperMode] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const { authUser } = useAuthUser();
  const userData = useUserData();
  const toggleDeveloperMode = () => setDeveloperMode(!developerMode);

  const isAccountOwner = userData?.userType?.toLowerCase() === 'account owner';

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonButton expand="block" onClick={toggleDeveloperMode}>
              {developerMode ? 'Disable' : 'Enable'} Developer Mode
            </IonButton>
          </IonItem>
          {developerMode && authUser && (
            <>
              <IonItem>
                <IonLabel>User ID: {authUser.uid}</IonLabel>
              </IonItem>
              {isAccountOwner && (
                <IonItem>
                  <IonButton expand="block" onClick={() => setShowAddUserModal(true)}>Add User</IonButton>
                </IonItem>
              )}
            </>
          )}
        </IonList>
        {isAccountOwner && (
          <IonModal isOpen={showAddUserModal} onDidDismiss={() => setShowAddUserModal(false)}>
            <AddUserModel isOpen={showAddUserModal} onClose={() => setShowAddUserModal(false)} />
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Settings;
