import { IonIcon, IonButton, IonRouterLink } from '@ionic/react';
import React from 'react';
import { barcodeSharp } from 'ionicons/icons';
import './LoggedInHeader.css';
import { getAuth, signOut } from 'firebase/auth';

const HeaderButton: React.FC<any> = ({ text, url }: { text: string, url: string }) => {
    return (
        <IonRouterLink className="header-button" routerLink={url}>
            {text}
        </IonRouterLink>
    );
};

const HeaderLogo: React.FC<any> = () => {
    return <a className="header-logo" href="/desktop-homepage"><IonIcon icon={barcodeSharp}></IonIcon>Cabinet Scanner</a>;
};

const LoggedInHeader: React.FC = () => {
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            // Redirect to homepage or login page after sign out if needed
            window.location.href = '/desktop-homepage';
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div id="header">
            <div className="header-section">
                <HeaderLogo />
            </div>
            <div className="header-section">
                <HeaderButton text="Upload" url="/desktop-uploadpage" />
                <HeaderButton text="Cabinet Log" url="/current-inventory" />
                <HeaderButton text="Settings" url="/desktop-settings" />
            </div>
            <div className="header-section">
                {/* Updated to make the logout button look like the login button */}
                <IonButton className="header-button" onClick={handleLogout} fill="clear">Logout</IonButton>
            </div>
        </div>
    );
};

export default LoggedInHeader;
