/**
 * LoginModal.tsx
 * 
 * This component renders a modal dialog for user login, offering multiple authentication methods including
 * email/password, Google, and Apple sign-in. It leverages Ionic components for UI and custom authentication
 * components for different sign-in methods. Upon successful authentication, a callback is triggered to
 * inform the parent component of the login success.
 * 
 * Props:
 * - onLoginSuccess: A callback function that is called when the login is successful.
 * 
 * Usage:
 * This component is intended to be used within pages or components that require user authentication before
 * providing access to certain features or content. It provides a unified interface for various authentication
 * methods and handles the visibility and dismissal of the modal based on user actions.
 */

import React, { useState } from 'react';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonText,
  IonButton
} from '@ionic/react';
import GoogleSignInButton from '../authentication/GoogleSignIn';
import AppleSignInButton from '../authentication/AppleSignIn';
import EmailSignIn from '../authentication/EmailSignIn';
import SignUpModal from '../pages/SignUpPage'; // Adjust this path as needed
import './LoginModalDesktop.css';

interface LoginModalProps {
  onLoginSuccess: () => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ onLoginSuccess, showModal, setShowModal }) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleSuccessfulLogin = () => {
    setShowModal(false);
    onLoginSuccess();
  };

  // Updated to manage sign-up modal visibility
  const handleSignUpClick = () => {
    setShowSignUpModal(true); // Show sign-up modal instead of navigating
  };

  return (
    <>
      <IonModal isOpen={showModal} className="login-modal" onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className="login-container">
            <EmailSignIn onSuccessfulLogin={handleSuccessfulLogin} />
            <div className="social-login-buttons">
              <IonText className="or-separator">Or via</IonText>
              <GoogleSignInButton onSuccessfulLogin={handleSuccessfulLogin} />
              <AppleSignInButton />
              <IonButton className="unique-signup-btn" expand="block" onClick={handleSignUpClick}>
                Sign Up
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonModal>

      {/* Sign-Up Modal */}
      <SignUpModal isOpen={showSignUpModal} onClose={() => setShowSignUpModal(false)} />
    </>
  );
};

export default LoginModal;
