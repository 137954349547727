// Import dependencies and components for the login pageieved
// Part 1 - ChatGPT
import { MLKitBarcodeScanner } from '../../components/capacitor-mlkit-barcode-scanning';
import { useState, useEffect } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import QuaggaScanner from '../../components/QuaggaScanner';
import { Capacitor } from '@capacitor/core';
import './Tab2.css'; // Styling for this component

// Log initialization details for diagnostic purposes
// part 2 - ChatGPT
console.log('Tab2.tsx file loaded');
console.log('Initial load - Window object:', window);
console.log('Initial load - Capacitor object on window:', window.Capacitor);

// Determine if the platform is web for conditional rendering
const isWebPlatform = Capacitor.getPlatform() === 'web';
console.log('Is web platform:', isWebPlatform);

// Define the main component for Tab 2
// part 3 - ChatGPT
const Tab2 = () => {
  // State hooks for managing selected status, success, and failure messages
  const [selectedStatus, setSelectedStatus] = useState<string>('Received');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [failureMessage, setFailureMessage] = useState<string | null>(null);
  
  // Log component function entry for debugging
  console.log('Tab2 component function entered');

  // Handler for barcode detection events
  const handleDetected = (code: string) => {
    console.log('Detected code:', code);
    handleSuccess(`Code ${code} detected`); // Invoke success handler
  };

  // Handlers for scan success and failure, managing respective state updates and logging
  // Part 4 - ChatGPT
  const handleSuccess = (message: string) => {
    console.log('Scan success:', message);
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(null), 3000); // Clear message after 3 seconds
  };

  const handleFailure = (message: string) => {
    console.error('Scan failure:', message);
    setFailureMessage(message);
    setTimeout(() => setFailureMessage(null), 3000); // Clear message after 3 seconds
  };

  // Log pre-render state for debugging
  // Part 5 - ChatGPT
  console.log('Before rendering - Selected status:', selectedStatus);
  console.log('Before rendering - Success message:', successMessage);
  console.log('Before rendering - Failure message:', failureMessage);

  // Render the component UI with conditionally displayed elements based on platform and state
  // Part 6 - ChatGPT
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="camera-view-container">
          {/* Conditionally render scanner components based on platform */}
          {isWebPlatform ? (
            <QuaggaScanner
              selectedStatus={selectedStatus}
              onDetected={handleDetected}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
            />
          ) : (
            <MLKitBarcodeScanner
              selectedStatus={selectedStatus}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
            />
          )}
        </div>
        {/* Display success or failure messages as pop-ups */}
        {successMessage && <div className="success-pop-up">{successMessage}</div>}
        {failureMessage && <div className="failure-pop-up">{failureMessage}</div>}
        {/* Dropdown for selecting the scan status */}
        <div className="status-select-container">
          <IonLabel>Status:</IonLabel>
          <IonSelect value={selectedStatus} onIonChange={e => setSelectedStatus(e.detail.value)}>
            <IonSelectOption value="Received">Received</IonSelectOption>
            <IonSelectOption value="Processed">Processed</IonSelectOption>
            <IonSelectOption value="Shipped">Shipped</IonSelectOption>
          </IonSelect>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
