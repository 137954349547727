/**
 * UserManager.tsx
 * 
 * This module is responsible for managing authentication state within the application. It utilizes Firebase Authentication
 * to listen for changes in the user's authentication status and provides this information through a React Context.
 * This allows authenticated user information to be accessible globally across the application, facilitating the implementation
 * of authentication-dependent features and UI components.
 * 
 * Dependencies:
 * - React: For the component and hooks system.
 * - Firebase Auth: For authentication state management and to listen for authentication state changes.
 * 
 * The UserProvider component encapsulates the logic for monitoring authentication state changes and provides the
 * authenticated user's information (if available) to the rest of the application. It also exports a custom hook,
 * `useAuthUser`, for easy access to the authentication state in functional components.
 */

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Defines the structure for authenticated user information.
interface AuthUser {
  uid: string;
  email?: string | null; // Email is optional and can be null.
}

// Specifies the shape of the context for providing authenticated user info.
interface UserContextProps {
  authUser: AuthUser | null; // Contains the current authenticated user's information or null.
}

// Creates a React context for authenticated user information with an initial value of undefined.
const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  children: React.ReactNode; // Accepts any valid React child elements.
}

// UserProvider component that listens for Firebase Auth state changes and provides the auth state.
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null); // State to hold the current auth user.

  useEffect(() => {
    const auth = getAuth(); // Initializes Firebase Auth.
    // Sets up a listener for authentication state changes.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User signed in with UID:", user.uid); // Logs when a user signs in.
        setAuthUser({ uid: user.uid, email: user.email }); // Updates state with user info.
      } else {
        console.log("User signed out"); // Logs when a user signs out.
        setAuthUser(null); // Resets the auth state to null.
      }
    });
    // Cleanup function to unsubscribe from the auth state listener on component unmount.
    return () => unsubscribe();
  }, []);

  // Provides the current auth user information through context to child components.
  return (
    <UserContext.Provider value={{ authUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to consume the authenticated user context.
export const useAuthUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useAuthUser must be used within a UserProvider'); // Ensures correct usage within the context provider.
  }
  return context;
};

