import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonButton, IonLoading, IonModal } from '@ionic/react';
import LoginModal from '../../components/LoginModal';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import './Tab3.css';
import { useUserData } from '../../components/UserDataFetcher';
import { useHistory } from 'react-router-dom';
import ExcelImportButton from '../../components/ExcelImportButton';
import { getPortalUrl } from '../../components/stripeService';
import { FirebaseApp } from 'firebase/app';
import AddUserModel from '../../components/AddAuthorizedEmail'; // Make sure this path is correct
import { useScanner } from '../../contexts/ScannerContext';

const Tab3: React.FC<{ app: FirebaseApp }> = ({ app }) => {
  const { stopScanner, isScannerActive } = useScanner();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const userData = useUserData();
  const dealerId = userData?.dealerIds || 'defaultDealerId';
  const history = useHistory();
  const [showAddUserModal, setShowAddUserModal] = useState(false);


  useEffect(() => {
    if (isScannerActive) {
      stopScanner();
    }
  }, [isScannerActive, stopScanner]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(app), (currentUser) => {
      setIsUserLoggedIn(!!currentUser);
    });
    return () => unsubscribe();
  }, [app]);

  const handleSignOut = async () => {
    try {
      await signOut(getAuth(app));
      setIsUserLoggedIn(false);
      history.push('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleManageSubscriptions = async () => {
    setShowLoading(true);
    getPortalUrl(app).then(url => {
      window.location.href = url;
    }).catch(error => {
      console.error('Failed to get the portal URL:', error);
    }).finally(() => {
      setShowLoading(false);
    });
  };

  const goToSettings = () => {
    history.push('/settings');
  };

  // Check if the user is an "Account Owner"
  const isAccountOwner = userData?.userType === 'Account Owner';

  return (
    <IonPage>
      <IonContent fullscreen>
        {!isUserLoggedIn && <LoginModal onLoginSuccess={() => setIsUserLoggedIn(true)} showModal={false} setShowModal={function (show: boolean): void {
          throw new Error('Function not implemented.');
        } } />}
        {isUserLoggedIn && (
          <div className="navigate-button-container">
            <IonButton expand="block" onClick={handleSignOut}>Sign Out</IonButton>
            <IonButton expand="block" onClick={goToSettings}>Settings</IonButton>
            <IonButton expand="block" onClick={handleManageSubscriptions}>
              Manage Subscriptions
            </IonButton>
            {isAccountOwner && (
              <IonButton expand="block" onClick={() => setShowAddUserModal(true)}>Add User</IonButton>
            )}
            <ExcelImportButton dealerId={dealerId} />
          </div>
        )}
        <IonLoading isOpen={showLoading} message={'Please wait...'} />
        <IonModal isOpen={showAddUserModal} onDidDismiss={() => setShowAddUserModal(false)}>
          <AddUserModel isOpen={showAddUserModal} onClose={() => setShowAddUserModal(false)} />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
