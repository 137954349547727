import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  DocumentReference,
  doc,
  setDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

interface CheckoutSessionData {
  url?: string;
  error?: { message: string };
}

interface PortalSessionData {
  url?: string;
}

export const getCheckoutUrl = async (
  app: FirebaseApp,
  priceId: string,
  dealerId: string, // Ensured dealerId is a parameter
  cabinetManufacturer: string,
  userType: string
): Promise<string> => {
  console.log("[getCheckoutUrl] Starting checkout URL retrieval process");

  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) {
    console.error("[getCheckoutUrl] User is not authenticated");
    throw new Error("User is not authenticated");
  }
  console.log(`[getCheckoutUrl] Authenticated user ID: ${userId}`);

  // Instantiate Firestore
  const db = getFirestore(app);

  if (!dealerId) {
    console.error("[getCheckoutUrl] Dealer ID is undefined or empty.");
    throw new Error("Dealer ID is required.");
  }

  const dealerDocumentId = cabinetManufacturer === 'Wood-Mode' ? `WM-${dealerId}` : dealerId;

  // Add or update the user's document with the dealerId
// Add or update the user's document with the dealerId and userType
  try {
    await setDoc(doc(db, "dealers", dealerDocumentId), { accountOwner: userId }, { merge: true });
    console.log(`[getCheckoutUrl] Dealer document for ${dealerDocumentId} updated with accountOwner userId.`);
  } catch (error) {
    console.error("[getCheckoutUrl] Error setting accountOwner in dealers document:", error);
    throw error;
  } 

  try {
    // If handling multiple dealers is needed, consider adjusting the structure, e.g., using an array or a subcollection
    await setDoc(doc(db, "users", userId), { dealerIds: dealerDocumentId, userType: userType }, { merge: true });
  } catch (error) {
    console.error("[getCheckoutUrl] Error updating user document with dealerId and userType:", error);
    throw error;
  }

  // Proceed to create a checkout session
  const checkoutSessionRef = collection(db, "users", userId, "checkout_sessions");
  console.log(`[getCheckoutUrl] Adding document to 'checkout_sessions' for priceId: ${priceId}`);
  const docRef: DocumentReference = await addDoc(checkoutSessionRef, {
    price: 'price_1P1h30AEPjl4X7NrRiAZn28g',
    trial_period_days: 14,
    success_url: window.location.origin + '/success',
    cancel_url: window.location.origin + '/cancel',
  });

  console.log(`[getCheckoutUrl] Document added with ID: ${docRef.id}, setting up onSnapshot`);

  return new Promise<string>((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (doc) => {
      console.log("[getCheckoutUrl] onSnapshot triggered");

      if (!doc.exists()) {
        console.log("[getCheckoutUrl] Snapshot does not exist");
        return;
      }

      const sessionData: CheckoutSessionData = doc.data() as CheckoutSessionData;

      if (sessionData.error) {
        console.error(`[getCheckoutUrl] Error from snapshot: ${sessionData.error.message}`);
        unsubscribe();
        reject(new Error(`An error occurred: ${sessionData.error.message}`));
      } else if (sessionData.url) {
        console.log(`[getCheckoutUrl] Received Stripe Checkout URL: ${sessionData.url}`);
        unsubscribe();
        resolve(sessionData.url);
      } else {
        console.log("[getCheckoutUrl] Waiting for Stripe Checkout URL...");
      }
    });
  });
};

export const getPortalUrl = async (app: FirebaseApp): Promise<string> => {
  console.log("[getPortalUrl] Starting portal URL retrieval process");

  const auth = getAuth(app);
  const user = auth.currentUser;
  if (!user) {
    console.error("[getPortalUrl] User is not authenticated");
    throw new Error("User is not authenticated");
  }

  console.log(`[getPortalUrl] Authenticated user ID: ${user.uid}`);

  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");

    console.log("[getPortalUrl] Calling Firebase function to create portal link");
    const result = await functionRef({ customerId: user.uid, returnUrl: window.location.origin });
    const data: PortalSessionData = result.data as PortalSessionData;

    if (data.url) {
      console.log(`[getPortalUrl] Received portal URL: ${data.url}`);
      return data.url;
    } else {
      console.error("[getPortalUrl] No URL returned from Firebase function");
      throw new Error("No URL returned");
    }
  } catch (error) {
    console.error(`[getPortalUrl] Error obtaining portal URL: ${error}`);
    throw error;
  }
};

