/**
 * UserDataFetcher.js
 * 
 * This component is responsible for fetching user-specific data from Firestore based on the authenticated user's ID.
 * It utilizes the Firestore database to retrieve user data and provides this data throughout the application
 * using React Context. This allows child components to access user data seamlessly.
 * 
 * Dependencies:
 * - React: For building the component and utilizing hooks for state and effect management.
 * - Firebase Firestore: For fetching user data from the database.
 * - UserManager: Custom hook `useAuthUser` to access the authenticated user's details.
 * 
 * The component listens for changes in the authenticated user's state and fetches the corresponding user data
 * from Firestore. The fetched data is then made available to any child components through the `UserDataContext`.
 */

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useAuthUser } from './UserManager';

// Defines the structure for the user data expected from Firestore.
interface UserData {
  dealerIds: string; // Assumed to be a string
  userType: string; // Adding userType field
  // ... other fields if necessary
}

// Creates a React context for user data with an initial state of null.
const UserDataContext = createContext<{ userData: UserData | null }>({ userData: null });

interface UserDataFetcherProps {
  children: React.ReactNode;
}

// The UserDataFetcher component.
const UserDataFetcher: React.FC<UserDataFetcherProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const { authUser } = useAuthUser(); // Retrieves the current authenticated user.
  const db = getFirestore(); // Initializes Firestore.

  // Effect hook to fetch user data when the authenticated user changes.
  useEffect(() => {
    const fetchUserData = async () => {
      const uid = authUser?.uid;

      if (uid) {
        console.log("UserDataFetcher: Activated for UID:", uid); // Log when data fetching is activated
        const userDocRef = doc(db, 'users', uid); // References the user's document in Firestore.

        try {
          const userDocSnap = await getDoc(userDocRef); // Attempts to fetch the user document.

          if (userDocSnap.exists()) {
            const data = userDocSnap.data() as UserData; // Casts the document data to UserData type.
            setUserData(data); // Updates state with fetched user data.
            console.log("UserDataFetcher: Data retrieved successfully", data); // Log the retrieved data
          } else {
            console.log(`UserDataFetcher: No user document found for UID: ${uid}`);
          }
        } catch (error) {
          console.error("UserDataFetcher: Error during Firestore query", error);
        }
      } else {
        console.log("UserDataFetcher: No auth user ID available for data fetching");
      }
    };

    fetchUserData();
  }, [authUser, db]);

  // Provides the fetched user data to child components.
  return (
    <UserDataContext.Provider value={{ userData }}>
      {children}
    </UserDataContext.Provider>
  );
};

// Custom hook to enable easy access to user data from within child components.
export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error('useUserData must be used within a UserDataFetcher');
  }
  return context.userData;
};

export default UserDataFetcher;
