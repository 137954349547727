import React, { useState } from 'react';
import { IonIcon, useIonRouter } from '@ionic/react';
import { barcodeSharp } from 'ionicons/icons';
import './Header.css';
import LoginModalDesktop from '../../../components/LoginModalDesktop'; // Ensure this path is correct.

const HeaderButton: React.FC<any> = ({ text, url, isDropdown }: { text: string, url: string, isDropdown: boolean }) => {
    return <a className="header-button" href={url}>{text}</a>;
};

const HeaderLogo: React.FC<any> = () => {
    return <a className="header-logo" href="/home"><IonIcon icon={barcodeSharp}></IonIcon>Cabinet Scanner</a>;
};

const Header: React.FC = () => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const router = useIonRouter();

    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    const onLoginSuccess = () => {
        setShowLoginModal(false); // Close the modal.
    
        console.log("Attempting to navigate to /desktop-uploadpage...");
        // router.push doesn't return a Promise; no `.then()` should be used here
        router.push('/desktop-uploadpage');
    
        // If you need to perform actions after navigation, consider using useEffect to watch for route changes
        // or ensure the actions don't rely on the completion of router.push.
    
        // Incorrect usage example that would cause your error:
        // router.push('/desktop-homepage').then(() => console.log("Navigation done")).catch((error: any) => console.error("Navigation error:", error));
    
        // Correct approach: Just log the attempt, the actual navigation doesn't support promise-based feedback
        console.log("Navigation to /desktop-uploadpage attempted");
    };
    

    return (
        <div id="header">
            <div className="header-section">
                <HeaderLogo />
            </div>
            <div className="header-section">
                <HeaderButton text="Home" url="/desktop-homepage" />
                <HeaderButton text="Pricing" url="/pricing-page" />
                <HeaderButton text="Contact" url="/contact-page" />
            </div>
            <div className="header-section">
                <button className="header-button" onClick={handleLoginClick}>Login / Sign-Up</button>
                <LoginModalDesktop 
                    onLoginSuccess={onLoginSuccess} 
                    showModal={showLoginModal} 
                    setShowModal={setShowLoginModal} 
                />
            </div>
        </div>
    );
};

export default Header;
