import React, { useEffect, useContext } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import {
  IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar,
  IonTabButton, IonTabs, isPlatform, setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cameraOutline, listOutline, settingsOutline } from 'ionicons/icons';
import Tab1 from './pages/Mobile/Tab1';
import Tab2 from './pages/Mobile/Tab2';
import Tab3 from './pages/Mobile/Tab3';
import Settings from './pages/Mobile/Settings';
import DesktopUploadPage from './pages/Desktop/DesktopUploadPage';
import CurrentInventory from './pages/Desktop/CurrentInventory';
import { UserProvider, useAuthUser } from './components/UserManager';
import UserDataFetcher from './components/UserDataFetcher';
import LoginPage from './pages/Mobile/LoginPage';
import DesktopSettings from './pages/Desktop/DesktopSettings';
import { DeveloperModeProvider } from './contexts/DeveloperModeContext';
import DesktopHomepage from './pages/Desktop/DesktopHomepage/DesktopHomepage';
import PricingPage from './pages/Desktop/DesktopHomepage/PricingPage';
import ContactPage from './pages/Desktop/DesktopHomepage/ContactPage';
import CheckoutSuccess from './pages/SignUpSuccessPage';
import SignUpPage from './pages/SignUpPage';
// Ionic CSS and theme variables
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.css';
import { ScannerProvider } from './contexts/ScannerContext';

setupIonicReact();

const App: React.FC = () => {
  return (
    <ScannerProvider>
    <UserProvider>
      <DeveloperModeProvider>
        <IonApp>
          <IonReactRouter>
            <UserDataFetcher>
              <ProtectedRoutes />
            </UserDataFetcher>
          </IonReactRouter>
        </IonApp>
      </DeveloperModeProvider>
    </UserProvider>
    </ScannerProvider>
  );
};

const ProtectedRoutes: React.FC = () => {
  const history = useHistory();
  const { authUser } = useAuthUser(); // Correctly use useAuthUser hook here
  const isDesktop = !isPlatform('mobile') && !isPlatform('hybrid');

  useEffect(() => {
    if (!authUser && !isDesktop) {
      history.push('/login');
    }
  }, [authUser, isDesktop, history]);

  const publicDesktopRoutes = (
    <>
      <Route path="/signup" component={SignUpPage} />
      <Route path="/success" component={CheckoutSuccess} />
      <Route path="/desktop-homepage" component={DesktopHomepage} />
      <Route path="/pricing-page" component={PricingPage} />
      <Route path="/contact-page" component={ContactPage} />
      {!authUser && <Route exact path="/" render={() => <Redirect to="/desktop-homepage" />} />}
    </>
  );

  const publicMobileRoutes = (
    <>
      {/* Placeholder for future public mobile routes */}
      <Route exact path="/login" component={LoginPage} />
      {/* Add more public mobile routes here */}
    </>
  );

  // Authenticated Desktop Routes
  const authenticatedDesktopRoutes = (
    <>
      <Route path="/desktop-uploadpage" component={DesktopUploadPage} />
      <Route path="/current-inventory" component={CurrentInventory} />
      <Route path="/desktop-settings" component={DesktopSettings} />
    </>
  );

  // Unauthenticated users
  if (!authUser) {
    return (
      <>
        {isDesktop ? publicDesktopRoutes : publicMobileRoutes}
      </>
    );
  }

  // Mobile/hybrid routes for authenticated users
  if (isPlatform('mobile') || isPlatform('hybrid')) {
    return (
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/tab1" component={Tab1} />
          <Route exact path="/tab2" component={Tab2} />
          <Route exact path="/tab3" component={Tab3} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/" render={() => <Redirect to="/tab1" />} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/tab1">
            <IonIcon icon={listOutline} />
            <IonLabel>Cabinet List</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={cameraOutline} />
            <IonLabel>Scanner</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/tab3">
            <IonIcon icon={settingsOutline} />
            <IonLabel>More</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    );
  }

  // Desktop routes for authenticated users
  return (
    <>
      {publicDesktopRoutes}
      {authenticatedDesktopRoutes}
    </>
  );
};

export default App;

