import { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebaseConfig'; // Adjust the path as necessary
import { IonToast } from '@ionic/react'; // Import IonToast component

interface SignUpParams {
  email: string;
  password: string;
  manufacturer: string;
}

export const useSignUp = (onSuccess?: () => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false); // Add state for showing toast
  const [toastMessage, setToastMessage] = useState(''); // Add state for toast message

  const signUpUser = async ({ email, password, manufacturer }: SignUpParams) => {
    setIsLoading(true);

    try {
      // Search for authorized dealer emails
      const dealersCollectionRef = collection(db, "dealers");
      const q = query(dealersCollectionRef, where("authorizedEmails", "array-contains", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error("Email is not authorized by any dealer.");
      }

      // Assuming the first match is the dealer we want
      const dealerId = querySnapshot.docs[0].id;

      // Proceed with user creation
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
      const userData = {
        email,
        manufacturer,
        userType: "User",
        dealerIds: dealerId, // Save the matching dealer's document ID
      };

      await setDoc(doc(db, "users", userId), userData);

      console.log("User account created successfully.");
      if (onSuccess) onSuccess();

      // Show success toast message
      setToastMessage('User account created successfully!');
      setShowToast(true);
    } catch (error) {
        // Check if error is an instance of Error and then access its message property
        if (error instanceof Error) {
          console.error("Error during user sign up:", error.message);
          alert(`Error during sign up: ${error.message}`);
        } else {
          // Handle cases where error might not be an instance of Error
          console.error("Error during user sign up:", error);
          alert("Error during sign up. Please try again.");
        }
      }
      
  };

  return { signUpUser, isLoading, showToast, toastMessage, setShowToast };
};
