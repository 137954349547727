import React, { useState } from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonToolbar, IonTitle, IonText, IonInput, IonItem, IonLabel } from '@ionic/react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Make sure to import your actual Firestore instance

interface CustomManufacturerPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  userData: {
    email: string;
    password: string; // Reminder: Handle the password securely!
    dealerId: string;
    customManufacturer: string;
    userType: string;
  };
}

const CustomManufacturerPopUp: React.FC<CustomManufacturerPopUpProps> = ({ isOpen, onClose, userData }) => {
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [cabinetManufacturer, setCabinetManufacturer] = useState(''); // New state for cabinet manufacturer

  const handleSubmission = async () => {
    // Construct the document to be saved
    const docData = {
      ...userData,
      contactName,
      contactPhone,
      cabinetManufacturer, // Include cabinet manufacturer in docData
      date: Timestamp.now()
    };

    try {
      // Add a new document with a generated id in 'customManufacturerAttempts' collection
      await addDoc(collection(db, 'customManufacturerAttempts'), docData);
      console.log('Document successfully written!');
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    // Close the pop-up after submission
    onClose();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Custom Manufacturer Interest</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText>
          <h2>Thank You for Your Interest!</h2>
          <p>We currently only support select manufacturers, but we would love to expand to include your suggested cabinet line.</p>
          <p>Please provide additional contact information, cabinet manufacturer, and we will reach out to you.</p>
          <p>You can also directly email us at Cristopher.Blunt@CabinetScanner.com</p>
        </IonText>
        <IonItem>
          <IonLabel position="floating">Contact Name</IonLabel>
          <IonInput value={contactName} onIonChange={e => setContactName(e.detail.value!)} />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Contact Phone Number</IonLabel>
          <IonInput value={contactPhone} onIonChange={e => setContactPhone(e.detail.value!)} />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Cabinet Manufacturer</IonLabel>
          <IonInput value={cabinetManufacturer} onIonChange={e => setCabinetManufacturer(e.detail.value!)} />
        </IonItem>
        <IonButton expand="block" onClick={handleSubmission}>Submit</IonButton>
        <IonButton expand="block" onClick={onClose}>Close</IonButton>
      </IonContent>
    </IonModal>
  );
};

export default CustomManufacturerPopUp;
