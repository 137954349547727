import React, { useState } from 'react';
import './PricingPage.css'; // Make sure this path is correct
import Header from './Header';
import screenshot1 from '../../../../public/assets/Screenshot1.png'; // Adjust the path as necessary
import SignUpPage from '../../../pages/SignUpPage'; // Adjust the path as necessary

const PricingPage: React.FC = () => {
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    return (
        <div className="pricing-page-wrapper">
            <Header />
            <div className="content-wrapper">
                <div className="desktop-pricing">
                    <div className="pricing-frame-wrapper">
                        <div className="pricing-frame">
                            <div className="div">
                                <div className="start-saving-up-to-wrapper">
                                    <p className="start-saving-up-to">
                                        <span className="text-wrapper">Start saving </span>
                                        <span className="span">up to</span>
                                        <span className="text-wrapper"> 50% on labor for as little as $24.99</span>
                                        <span className="text-wrapper-2">/</span>
                                        <span className="text-wrapper">month</span>
                                    </p>
                                </div>
                                <div className="basic-package-wrapper">
                                    <p className="basic-package">
                                        <span className="text-wrapper">
                                            Basic Package:
                                            <br />
                                        </span>
                                        <span className="text-wrapper-2">
                                            2 Admins
                                            <br />
                                            Unlimited App Users
                                            <br />
                                            Unlimited Scans
                                            <br />
                                            Unlimited Services
                                        </span>
                                    </p>
                                </div>
                                <div className="ready-to-get-started-wrapper">
                                    <p className="ready-to-get-started">
                                        <span className="span">Ready to get started?</span>
                                    </p>
                                </div>
                                <div className="pricingstart-your-wrapper">
                                    <button className="pricingstart-your" onClick={() => setShowSignUpModal(true)}>Start Your FREE Trial Now!</button>
                                </div>
                            </div>
                            <div className="pricing-iphone-pro-max-wrapper">
                                <div className="iphone-pro-max">
                                    <img className="image" alt="Product Showcase" src={screenshot1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SignUpPage isOpen={showSignUpModal} onClose={() => setShowSignUpModal(false)} />
        </div>
    );
};

export default PricingPage;


