import React from 'react';
import './ContactPage.css';
import Header from './Header';
import Headshot from '../../../../public/assets/Headshot.png'; // Ensure this path is correct based on your project structure

const DesktopContact: React.FC = () => {
  return (
    <div className="desktop-contact">
      <div className="div">
        <p className="text-wrapper">Get In Touch With The Cabinet Scanner Team</p>
        <p className="p">
          Questions? Comments? Want this for your cabinet line? We’d love to hear from you. Fill out the form or reach
          out directly.
        </p>
        <p className="EMAIL-cristopher">
          <span className="span">
            EMAIL
            <br />
          </span>
          <span className="text-wrapper-2">Cristopher.Blunt@CabinetScanner.com</span>
        </p>
        <p className="PHONE">
          <span className="span">
            PHONE
            <br />
          </span>
          <span className="text-wrapper-2">832-322-1452</span>
        </p>
        <img className="image" alt="Headshot" src={Headshot} />
      </div>
    </div>
  );
};

const ContactPage: React.FC = () => {
    return (
        <div className="wrapper">
            <Header />
            <DesktopContact />
        </div>
    )
};

export default ContactPage;