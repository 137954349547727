// src/contexts/ScannerContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ScannerContextType {
  isScannerActive: boolean;
  stopScanner: () => void;
}

const ScannerContext = createContext<ScannerContextType | undefined>(undefined);

export const useScanner = () => {
  const context = useContext(ScannerContext);
  if (context === undefined) {
    throw new Error('useScanner must be used within a ScannerProvider');
  }
  return context;
};

export const ScannerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isScannerActive, setIsScannerActive] = useState(false);

  const stopScanner = () => {
    setIsScannerActive(false);
    // Place any logic here needed to stop the actual scanner
    // For example, if using Quagga, call Quagga.stop();
  };

  return (
    <ScannerContext.Provider value={{ isScannerActive, stopScanner }}>
      {children}
    </ScannerContext.Provider>
  );
};
