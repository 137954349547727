import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import './GoogleSignIn.css';

interface GoogleSignInButtonProps {
    onSuccessfulLogin?: () => void;
}

export const signInWithGoogle = async (onSuccessfulLogin?: () => void) => {
    console.log("Google Sign-In button clicked!");

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
        const result = await signInWithPopup(auth, provider);
        const googleCredential = GoogleAuthProvider.credentialFromResult(result);
        const token = googleCredential?.accessToken;
        const user = result.user;
        console.log("User signed in:", user);

        if (onSuccessfulLogin) {
            onSuccessfulLogin();
        }
    } catch (error) {
        console.error("Error signing in with Google: ", error);
    }
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({ onSuccessfulLogin }) => {
    return (
        <div className="google-signin-button" onClick={() => signInWithGoogle(onSuccessfulLogin)}>
            <span className="google-signin-icon"></span>
            Sign in with Google
        </div>
    );
}

export default GoogleSignInButton;
