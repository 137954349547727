//Part 1 - ChatGPT
import React, { useEffect, useRef } from 'react';
import Quagga from 'quagga';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Adjust the import path as necessary

interface QuaggaScannerProps {
  onDetected: (code: string) => void;
  selectedStatus: string;
  onSuccess: (message: string) => void;
  onFailure: (message: string) => void;
}

const QuaggaScanner: React.FC<QuaggaScannerProps> = ({ selectedStatus, onDetected, onSuccess, onFailure }) => {
  const refViewport = useRef<HTMLDivElement>(null);
  let lastDetectionTime = useRef<number>(0);

//Part 2 - Enhanced useEffect with Visibility Change Logic
useEffect(() => {
  console.log('QuaggaScanner useEffect - Initializing Quagga...');

  const quaggaConfig = {
    inputStream: {
      name: "Live",
      type: "LiveStream",
      target: refViewport.current,
      constraints: {
        width: { min: 640 },
        height: { min: 480 },
        facingMode: "environment",
        aspectRatio: { min: 1, max: 100 },
      },
      // Defining the scan area
      area: {
        top: "30%",    // Start scanning from 30% from the top of the video feed
        right: "1%",   // End scanning 1% from the right of the video feed
        left: "1%",    // Start scanning from 1% from the left of the video feed
        bottom: "30%"  // End scanning 30% from the bottom of the video feed
      },
    },
    locator: {
      patchSize: "medium",
      halfSample: true,
    },
    numOfWorkers: navigator.hardwareConcurrency > 4 ? 4 : navigator.hardwareConcurrency,
    decoder: {
      readers: ["code_128_reader"],
    },
    locate: false, // Set to false to use the defined area for scanning
  };

  Quagga.init(quaggaConfig, (err: any) => {
    if (err) {
      console.error('Quagga.init error:', err);
      return;
    }
    console.log("Quagga initialization successful.");
    Quagga.start();
    // Apply willReadFrequently after a delay to ensure canvas has been created
    setTimeout(() => {
      const canvasElements = document.getElementsByTagName('canvas');
      for (let canvas of canvasElements) {
        canvas.setAttribute('willReadFrequently', 'true');
      }
    }, 1000); // Delay may need adjustment based on initialization time    
  });

  // Function to handle visibility change
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      Quagga.stop();
      console.log('Tab is hidden. Quagga stopped.');
    }
  };

  // Add visibility change event listener
  document.addEventListener('visibilitychange', handleVisibilityChange);


    // Keep the useEffect hook and Quagga initialization from the older, better-performing code unchanged.
    //Part 3 - ChatGPT
    // Integrate this updated drawCornerMarkers function into your useEffect hook
    const drawCornerMarkers = () => {
      const drawingCanvas = refViewport.current?.querySelector('canvas') as HTMLCanvasElement;
      if (!drawingCanvas) return;

      const ctx = drawingCanvas.getContext('2d');
      if (ctx) {
        // Define corner marker properties
        const markerSize = 20; // Size of each corner marker
        const lineWidth = 2; // Line width for the markers
        const color = 'black'; // Changed color of the markers to black
        const horizontalOffset = 25; // Adjusted to move in from the left and right
        const verticalOffset = 270; // Adjusted to move in from the top and bottom

        // Top-left corner
        ctx.beginPath();
        ctx.moveTo(horizontalOffset, verticalOffset + markerSize);
        ctx.lineTo(horizontalOffset, verticalOffset);
        ctx.lineTo(horizontalOffset + markerSize, verticalOffset);
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.stroke();

        // Top-right corner
        ctx.beginPath();
        ctx.moveTo(drawingCanvas.width - horizontalOffset - markerSize, verticalOffset);
        ctx.lineTo(drawingCanvas.width - horizontalOffset, verticalOffset);
        ctx.lineTo(drawingCanvas.width - horizontalOffset, verticalOffset + markerSize);
        ctx.stroke();

        // Bottom-left corner
        ctx.beginPath();
        ctx.moveTo(horizontalOffset, drawingCanvas.height - verticalOffset - markerSize);
        ctx.lineTo(horizontalOffset, drawingCanvas.height - verticalOffset);
        ctx.lineTo(horizontalOffset + markerSize, drawingCanvas.height - verticalOffset);
        ctx.stroke();

        // Bottom-right corner
        ctx.beginPath();
        ctx.moveTo(drawingCanvas.width - horizontalOffset, drawingCanvas.height - verticalOffset - markerSize);
        ctx.lineTo(drawingCanvas.width - horizontalOffset, drawingCanvas.height - verticalOffset);
        ctx.lineTo(drawingCanvas.width - horizontalOffset - markerSize, drawingCanvas.height - verticalOffset);
        ctx.stroke();
      }
    };

    // Call drawCornerMarkers within Quagga.onProcessed just as before
    Quagga.onProcessed((result: any) => {
      
      drawCornerMarkers(); // Ensure this is called here
});

    Quagga.onDetected((result: any) => {
      const now = Date.now();
    if (now - lastDetectionTime.current < 1000) {
      // If detected within the last second, don't process this scan
      return;
    }
    lastDetectionTime.current = now; // Update the last detection time

    console.log("Barcode detected and decoded:", result.codeResult.code);
    updateFirestoreDocument(result.codeResult.code); // Call Firestore update function
  });

//Part 4
  // Function to update Firestore and perform haptic feedback
  const updateFirestoreDocument = async (scannedCode: string) => {
    // Add log to confirm function call
    console.log(`Attempting to update Firestore for scanned code: ${scannedCode}`);

    // Sanitize the scanned code if necessary
    const sanitizedCode = scannedCode.replace(/\s/g, ''); // Adjust this line as needed
    const docRef = doc(db, 'cabinetLog', sanitizedCode);

    try {
      // Add log to confirm Firestore read attempt
      console.log(`Checking Firestore for document with ID: ${sanitizedCode}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Add log to confirm document exists
        console.log(`Document found in Firestore with ID: ${sanitizedCode}, updating...`);
        await updateDoc(docRef, { scanStatus: selectedStatus });

        // Add log to confirm document update
        console.log(`Document with ID: ${sanitizedCode} successfully updated.`);
        onSuccess(`Document with ID: ${sanitizedCode} successfully updated.`);
        Haptics.notification({ type: NotificationType.Success });
      } else {
        // Add log for no document found
        console.log(`No document found in Firestore with ID: ${sanitizedCode}.`);
        onFailure(`No document found with ID: ${sanitizedCode}.`);
        Haptics.notification({ type: NotificationType.Warning });
      }
    } catch (error) {
      // Add log for error case
      console.error(`Error updating document in Firestore with ID: ${sanitizedCode}:`, error);
      onFailure(`Error updating document with ID: ${sanitizedCode}: ${error}`);
      Haptics.notification({ type: NotificationType.Error });
    }
  };
    const drawBoxes = (boxes: any[]) => {
      const drawingCanvas = refViewport.current?.querySelector('canvas') as HTMLCanvasElement;
      if (drawingCanvas) {
        const videoElement = refViewport.current?.querySelector('video');
        if (videoElement) {
          // Match canvas dimensions with video dimensions
          drawingCanvas.width = videoElement.offsetWidth;
          drawingCanvas.height = videoElement.offsetHeight;
        }
        const ctx = drawingCanvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
          boxes.filter((box: any) => box).forEach((box: any) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, ctx, { color: 'green', lineWidth: 2 });
          });
        }
      }
    };
    
    
    return () => {
      Quagga.offProcessed(null);
      Quagga.offDetected(null);
      Quagga.stop();
      console.log('QuaggaScanner Cleanup - Stopped Quagga and removed detection handlers.');
      
     // Remove visibility change event listener
     document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    
        
        
        }, [onDetected]);

        return <div id="interactive" className="viewport" ref={refViewport} />;
        };
        
        export default QuaggaScanner;