/**
 * CurrentInventory.tsx
 * 
 * This component displays the current inventory of cabinet logs, allowing users to view and edit inventory items.
 * It integrates with Firebase Firestore to fetch and update inventory data, and utilizes MaterialReactTable for 
 * displaying the data in a tabular format with editable fields. Authentication state is managed via Firebase Auth
 * to display user-specific inventory and to enable user logout. The component also supports dark mode theming 
 * using Material-UI's ThemeProvider.
 * 
 * State:
 * - isUserLoggedIn: Tracks the user's authentication status.
 * - cabinetLogs: Array of inventory items fetched from Firestore.
 * - userDealerId: Dealer ID of the logged-in user, used for fetching user-specific inventory.
 * 
 * Effects:
 * - An effect to monitor authentication state changes and update isUserLoggedIn accordingly.
 * - An effect to fetch inventory logs from Firestore upon component mount or when userDealerId changes.
 * 
 * Functions:
 * - handleLogout: Signs out the current user using Firebase Auth.
 * - fetchCabinetLogs: Fetches user-specific inventory logs from Firestore.
 * - updateFirestoreDocument: Updates a specific document in Firestore with new data.
 * 
 * Usage:
 * This component is intended to be used as part of an inventory management system, where authenticated users
 * can view and manage the inventory of cabinet logs. It provides a detailed view of inventory items, including
 * order number, item number, quantity, description, and timestamps for checked-in and checked-out status.
 */
//--Part 1 - ChatGPT
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonMenu, IonList, IonItem, IonMenuButton, IonButtons, IonButton } from '@ionic/react';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { MaterialReactTable, MRT_Cell } from 'material-react-table';
import { collection, getDocs, Timestamp, query, where, Query } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useUserData } from '../../components/UserDataFetcher';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import LoggedInHeader from './LoggedInHeader';

//--Part 2 - ChatGPT
// Defines the data structure for inventory samples and editable cell properties
interface Sample {
  id?: string;
  itemNumber?: string;
  orderNumber?: string;
  quantity?: string;
  description?: string;
  scanStatus?: string;
}

// Define a type for the cell object in MaterialReactTable
interface EditableCellProps {
  cell: MRT_Cell<Sample>;
}


//--Part 3 - ChatGPT
// Main component for current inventory management
import { doc, updateDoc } from 'firebase/firestore';

const CurrentInventory: React.FC = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [cabinetLogs, setCabinetLogs] = useState<Sample[]>([]);
  const userData = useUserData();
  const auth = getAuth();
  const userDealerId = userData?.dealerIds;
  const [hasDealerId, setHasDealerId] = useState<boolean>(true); // New state to track presence of dealerId
   
   // Authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsUserLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, [auth]);

   // Fetches cabinet logs from Firestore based on user's dealer ID
  useEffect(() => {
    const fetchCabinetLogs = async () => {
      const trimmedUserDealerId = userData?.dealerIds.trim();
      if (!trimmedUserDealerId) {
        // If there's no dealer ID, don't attempt to fetch data and inform the user
        setHasDealerId(false);
        return;
      }

      setHasDealerId(true);

      const cabinetLogCollection = collection(db, 'cabinetLog');
      let cabinetLogQuery: Query;

      if (trimmedUserDealerId) {
        cabinetLogQuery = query(cabinetLogCollection, where("dealerId", "==", trimmedUserDealerId));
      } else {
        cabinetLogQuery = query(cabinetLogCollection);
      }

      const cabinetLogSnapshot = await getDocs(cabinetLogQuery);
      const cabinetLogsArray = cabinetLogSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as Sample
      }));

      setCabinetLogs(cabinetLogsArray);
    };

    fetchCabinetLogs();
  }, [userDealerId]);

   // Handles user logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

   // Theme customization for Material-UI components
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

   // Updates a document in Firestore with new data
  const updateFirestoreDocument = async (id: string, updatedData: Partial<Sample>) => {
    const docRef = doc(db, 'cabinetLog', id);
    try {
      await updateDoc(docRef, updatedData);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

   // Editable cell component for in-place editing within the table
  const EditableCell: React.FC<EditableCellProps> = ({ cell }) => {
    const [value, setValue] = useState(cell.getValue() as string);
  
    const onBlur = () => {
      const cellValue = cell.getValue() as string; // Cast to string to avoid 'undefined'
      if (value !== cellValue && cell.row.original.id) {
        const updatedRow = { ...cell.row.original, [cell.column.id]: value };
        updateFirestoreDocument(cell.row.original.id, updatedRow);
      }
    };
  
    return (
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  };
  

   //--Part 4 - ChatGPT  
   // Defines columns for MaterialReactTable with editable cells and timestamp formatting
  const columns = useMemo(
    () => [
      {
        accessorKey: 'orderNumber',
        header: 'Order Number',
        Cell: (props: EditableCellProps) => <EditableCell {...props} />,
      },
      {
        accessorKey: 'itemNumber',
        header: 'Item Number',
        Cell: ({ cell }) => <EditableCell cell={cell} />,
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        Cell: ({ cell }) => <EditableCell cell={cell} />,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        Cell: ({ cell }) => <EditableCell cell={cell} />,
      },
      {
        accessorKey: 'scanStatus',
        header: 'Scan Status',
        Cell: ({ cell }) => <EditableCell cell={cell} />,

      },
      
    ],
    []
  );

  interface EditableCellProps {
    cell: MRT_Cell<Sample>;
  }
  



  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);

 //-- Part 5 - ChatGPT
 // Renders the page layout including the menu, logout button, and inventory table within a dark theme provider
 return (
  <ThemeProvider theme={darkTheme}>
    <IonPage>
      <LoggedInHeader />
      <IonContent className="ion-padding">
        <div style={{ color: 'black' }}>
          <MaterialReactTable 
            columns={columns} 
            data={cabinetLogs} 
            enableColumnOrdering
            enableRowSelection 
            enablePagination
            onRowSelectionChange={setRowSelection}
            state={{ rowSelection }}
          />
        </div>
      </IonContent>
    </IonPage>
  </ThemeProvider>
);

 }
  export default CurrentInventory;