import React, { ChangeEvent, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { uploadToFirestore } from './FirestoreUpload';
import { getAuth } from 'firebase/auth';
import './ExcelImportButton.css';
import { IonButton, IonToast } from '@ionic/react';


interface ExcelImportButtonProps {
  dealerId: string;
}

// Update the component to accept props
const ExcelImportButton: React.FC<ExcelImportButtonProps> = ({ dealerId }) => {
  const [orderNumber, setOrderNumber] = useState<string | null>(null);
  const [rowData, setRowData] = useState<Array<{ itemNumber: string, quantity: string, description: string }>>([]);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false); // New state to track file selection
  const [showToast, setShowToast] = useState<boolean>(false); // New state for showing the toast message
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setIsUploaded(false); // Reset upload status when a new file is chosen
    setIsFileSelected(false); // Reset file selection status
    const file = event.target.files?.[0];
    if (!file) return;

    setIsFileSelected(true); // Set file selection status as soon as a file is chosen

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: file.name.endsWith('.xls') ? 'binary' : 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Extracting order number (example: from cell E2)
const orderNumberCell = worksheet['E2'];
if (orderNumberCell && orderNumberCell.v) {
  const orderNumberValue = orderNumberCell.v.toString();
  const parsedOrderNumber = orderNumberValue.replace('Order #: ', ''); // Remove the 'Order #: ' part
  setOrderNumber(parsedOrderNumber);
} else {
  setOrderNumber(null);
}


      // Extracting row data starting from row 7
      const extractedData = [];
      let row = 7; // Starting row
      while (worksheet[`C${row}`]) {
        let quantity = 'N/A';
        if (worksheet[`D${row}`]) {
          quantity = worksheet[`D${row}`].v;
        } else if (worksheet[`E${row}`]) {
          // Check if the cell is merged and find the top-left cell
          const mergedCells = worksheet['!merges'];
          const mergeRange = mergedCells?.find(m => m.s.c <= 4 && m.e.c >= 4 && m.s.r <= row && m.e.r >= row);
          if (mergeRange) {
            quantity = worksheet[XLSX.utils.encode_cell({ c: mergeRange.s.c, r: mergeRange.s.r })]?.v || 'N/A';
          }
        }

        extractedData.push({
          itemNumber: worksheet[`C${row}`].v,
          quantity: quantity,
          description: worksheet[`F${row}`]?.v || 'N/A', // Using 'N/A' if no description is specified
        });
        row++;
      }

      setRowData(extractedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleUploadToFirestore = async () => {
    const auth = getAuth();
    const userId = auth.currentUser ? auth.currentUser.uid : 'Unknown';
    let uploadSuccessful = false;
  
    if (orderNumber && rowData.length > 0) {
      for (const row of rowData) {
        let paddedItemNumber = row.itemNumber.toString().padStart(3, '0');
        const docName = `${orderNumber}${paddedItemNumber}`;
  
        const success = await uploadToFirestore('cabinetLog', docName, {
          orderNumber,
          itemNumber: row.itemNumber,
          quantity: row.quantity,
          description: row.description,
          scanStatus: 'Not Yet Scanned',
          uploadedBy: userId,
          cabinetLineId: 'BFOjR60OTxkix8Sg7NCn',
          dealerId: dealerId, // Use the dealerId from props
          jobName: '', // Ensure these fields are provided or remove them if not needed
          estimatedDeliveryDate: ''
        });
        if (success) uploadSuccessful = true;
      }
      setIsUploaded(true);
      if (uploadSuccessful) setShowToast(true); // Show success message if any document was uploaded successfully
    }
  };

  
  
  

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
  <div className="buttons-container" style={{ marginBottom: '10px' }}>
    {rowData.length > 0 && (
      <IonButton onClick={handleUploadToFirestore}>Looks good, upload to database</IonButton>
    )}
    <IonButton onClick={handleButtonClick} style={{ marginRight: '10px' }}>
      {isFileSelected || isUploaded ? 'Attach A Different Report' : 'Attach Cabinet Report'}
    </IonButton>
  </div>
        <div>
          {/* Assuming you will replace these placeholders with actual input fields */}
          <input placeholder="Job Name" style={{ display: 'none' }} />
          <input type="date" placeholder="Estimated Delivery Date" style={{ display: 'none' }} />
        </div>
      </div>
      {orderNumber && <p>Order Number: {orderNumber}</p>}
      <div>
        {rowData.map((row, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <p>Item Number: {row.itemNumber}</p>
            <p>Quantity: {row.quantity}</p>
            <p>Description: {row.description}</p>
          </div>
        ))}
      </div>
      <input 
        type="file" 
        accept=".xlsx, .xls" 
        onChange={handleFileUpload} 
        ref={fileInputRef} 
        style={{ display: 'none' }}
      />
      {/* IonToast for showing the success message */}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Successfully uploaded to database. View in cabinet log or scan on mobile."
        duration={2500}
        position="top"
      />
    </>
  );
  
  
};

export default ExcelImportButton;