import React from 'react';
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';
import './AppleSignIn.css';

export const signInWithApple = async () => {
  const auth = getAuth();
  const provider = new OAuthProvider('apple.com');

  try {
    const result = await signInWithPopup(auth, provider);
    
    const user = result.user;
    // Handle the signed-in user here

    // Retrieve the access token from the user object
    const token = user.getIdToken(); // This gets the Firebase ID token

    console.log("Apple ID Token: ", token);
  } catch (error) {
    console.error("Error signing in with Apple: ", error);
  }
};

const AppleSignInButton: React.FC = () => {
  return (
    <div className="apple-signin-button" onClick={signInWithApple}>
      Apple Sign In - Under Development
    </div>
  );
};

export default AppleSignInButton;
