

// capacitor-mlkit-barcode-scanning.tsx
//This is the google barcode scanner that works better on native.
import React, { useState, useEffect, FC } from 'react';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { IonButton, IonAlert } from '@ionic/react';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Adjust the import path as necessary


interface Props {
  selectedStatus: string;
  onSuccess: (message: string) => void;
  onFailure: (message: string) => void;
}

export const MLKitBarcodeScanner: FC<Props> = ({ selectedStatus, onSuccess, onFailure }) => {
  const [isSupported, setIsSupported] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  // Part 1: Check support and request permissions
  useEffect(() => {
    BarcodeScanner.isSupported().then((result) => {
      setIsSupported(result.supported);
    });
  }, []);

  const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === 'granted' || camera === 'limited';
  };

  // Part 2: Scan barcodes and update status
  const scanAndUpdateStatus = async () => {
    const permissions = await requestPermissions();
    if (!permissions) {
      setShowAlert(true);
      onFailure("Camera permissions not granted.");
      return;
    }

    const result = await BarcodeScanner.scan();
    if (result.barcodes.length > 0) {
      result.barcodes.forEach(async (barcode) => {
        const sanitizedBarcode = barcode.rawValue.replace(/\s+/g, '');
        await updateFirestoreDocument(sanitizedBarcode);
      });
    } else {
      onFailure("No barcodes scanned.");
    }
  };

  // Part 3: Update Firestore document
  const updateFirestoreDocument = async (scannedCode: string) => {
    const docRef = doc(db, 'cabinetLog', scannedCode);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, { scanStatus: selectedStatus });
        onSuccess(`Document with ID: ${scannedCode} successfully updated.`);
        Haptics.notification({ type: NotificationType.Success }); // Success feedback
      } else {
        onFailure(`No document found with ID: ${scannedCode}.`);
        Haptics.notification({ type: NotificationType.Warning }); // Warning feedback for no document found
      }
    } catch (error) {
      onFailure(`Error updating document with ID: ${scannedCode}: ${error}`);
      Haptics.notification({ type: NotificationType.Error }); // Error feedback
    }
  };

  // Part 4: Render component
  return (
    <>
      <IonButton onClick={scanAndUpdateStatus} disabled={!isSupported}>Start Barcode Scan</IonButton>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Permission denied'}
        message={'Please grant camera permission to use the barcode scanner.'}
        buttons={['OK']}
      />
    </>
  );
};
export default MLKitBarcodeScanner;
