import React, { createContext, useState, useContext, ReactNode } from 'react';

interface DeveloperModeContextType {
  developerMode: boolean;
  toggleDeveloperMode: () => void;
}

const DeveloperModeContext = createContext<DeveloperModeContextType>({
  developerMode: false,
  toggleDeveloperMode: () => {},
});

interface DeveloperModeProviderProps {
  children: ReactNode;
}

export const DeveloperModeProvider: React.FC<DeveloperModeProviderProps> = ({ children }) => {
  const [developerMode, setDeveloperMode] = useState(false);

  const toggleDeveloperMode = () => {
    setDeveloperMode(prevMode => !prevMode);
  };

  return (
    <DeveloperModeContext.Provider value={{ developerMode, toggleDeveloperMode }}>
      {children}
    </DeveloperModeContext.Provider>
  );
};

export const useDeveloperMode = () => {
  const context = useContext(DeveloperModeContext);
  if (!context) {
    throw new Error('useDeveloperMode must be used within a DeveloperModeProvider');
  }
  return context;
};
