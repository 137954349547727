import React from 'react';
import { IonModal, IonButton, IonContent, IonHeader, IonToolbar, IonTitle, IonText } from '@ionic/react';

interface UserTypePopUpProps {
  isOpen: boolean;
  onClose: () => void;
  userData: {
    email: string;
    password: string;
    dealerId: string;
    manufacturer: string;
    customManufacturer: string;
    userType: string;
  };
}

const UserTypePopUp: React.FC<UserTypePopUpProps> = ({ isOpen, onClose, userData }) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>User Type Notice</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText>
          <h2>Thank you for your interest!</h2>
          <p>
            It seems you've signed up as a 'User'. Please note that users need to be added by an
            account owner within the settings.
          </p>
          <p>If you are the account owner, please sign up as an 'Account Owner' to proceed.</p>
        </IonText>
        <IonButton expand="block" onClick={onClose}>Close</IonButton>
      </IonContent>
    </IonModal>
  );
};

export default UserTypePopUp;
