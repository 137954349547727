import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonButton, IonLoading, IonToast } from '@ionic/react';
import LoggedInHeader from './LoggedInHeader';
import AddUserModel from '../../components/AddAuthorizedEmail'; // Ensure this path is correct
import { getPortalUrl } from '../../components/stripeService';
import { FirebaseApp } from 'firebase/app';
import { useUserData } from '../../components/UserDataFetcher';

const DesktopSettings: React.FC<{ app: FirebaseApp }> = ({ app }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const userData = useUserData();

  useEffect(() => {
    if (userData && userData.userType.toLowerCase() !== 'account owner') {
      setShowAddUser(false);
    }
  }, [userData]);

  const handleManageSubscriptions = async () => {
    try {
      setShowLoading(true);
      const url = await getPortalUrl(app);
      window.location.href = url;
    } catch (error) {
      console.error('Failed to get the portal URL:', error);
    } finally {
      setShowLoading(false);
    }
  };

  const toggleAddUserModel = () => setShowAddUser(!showAddUser);
  const onAddUserSuccess = () => {
    setShowAddUser(false); // Close the modal
    setShowSuccessToast(true); // Show success message
  };

  return (
    <IonPage>
      <LoggedInHeader />
      <IonContent className="ion-padding">
        {userData && userData.userType.toLowerCase() === 'account owner' && (
          <IonButton expand="block" onClick={handleManageSubscriptions}>
            Manage Subscriptions
          </IonButton>
        )}
        {userData && userData.userType.toLowerCase() === 'account owner' && (
          <IonButton expand="block" onClick={() => toggleAddUserModel()}>
            Add User
          </IonButton>
        )}
        <IonLoading isOpen={showLoading} message={'Please wait...'} />
        <AddUserModel isOpen={showAddUser} onClose={onAddUserSuccess} />
        <IonToast
          isOpen={showSuccessToast}
          onDidDismiss={() => setShowSuccessToast(false)}
          message="User added successfully!"
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default DesktopSettings;
