import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import './EmailSignIn.css'; // Ensure this is properly linked to apply your styles

interface EmailSignInProps {
  onSuccessfulLogin: () => void;
}

const EmailSignIn: React.FC<EmailSignInProps> = ({ onSuccessfulLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);

  const auth = getAuth();

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onSuccessfulLogin();
    } catch (error) {
      console.error("Error signing in with email: ", error);
    }
  };

  const handleSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      onSuccessfulLogin();
    } catch (error) {
      console.error("Error creating user: ", error);
    }
  };

  return (
    <div>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="email-signin-input"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="email-signin-input"
      />
      {isSignUp ? (
        <>
          <div className="email-signin-button smaller" onClick={handleSignUp}>Sign Up</div>
          <p>
            Already have an account? <span onClick={() => setIsSignUp(false)}>Sign In</span>
          </p>
        </>
      ) : (
        <>
          <div className="email-signin-button smaller" onClick={handleSignIn}>Sign In</div>
          {/* Commenting out the Sign Up prompt for temporary removal */}
          {/*
          <p>
            Don't have an account? <span onClick={() => setIsSignUp(true)}>Sign Up</span>
          </p>
          */}
        </>
      )}
    </div>
  );
};

export default EmailSignIn;
