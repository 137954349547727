import React, { useEffect, useState, useMemo } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonInput,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
} from '@ionic/react';
import ExploreContainer from "../../components/ExploreContainer";
import { MaterialReactTable, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { collection, getDocs, Timestamp, where, query } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { useUserData } from '../../components/UserDataFetcher';
import './Tab1.css';
import { useDeveloperMode } from '../../contexts/DeveloperModeContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RefresherEventDetail } from '@ionic/core';
import { useScanner } from '../../contexts/ScannerContext';


interface Sample {
  id?: string;
  itemNumber?: string;
  orderNumber?: string;
  quantity?: string;
  description?: string;
  checkedInTimestamp?: Timestamp | null;
  checkedOutTimestamp?: Timestamp | null;
  scanStatus?: string;
}

const Tab1: React.FC = () => {
  const { stopScanner, isScannerActive } = useScanner();
  const [cabinetLogs, setCabinetLogs] = useState<Sample[]>([]);
  const testVersion = "Test Version: 7";
  const userData = useUserData();
  const userDealerId = userData?.dealerIds?.trim();
  const { developerMode } = useDeveloperMode();

  useEffect(() => {
    if (isScannerActive) {
      stopScanner();
    }
  }, [isScannerActive, stopScanner]);


  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
    },
  });


  
  const fetchCabinetLogs = async () => {
    let queryToExecute;
    const cabinetLogCollection = collection(db, 'cabinetLog');
    
    if (userDealerId) {
      queryToExecute = query(cabinetLogCollection, where("dealerId", "==", userDealerId));
    } else {
      queryToExecute = cabinetLogCollection;
    }

    const cabinetLogSnapshot = await getDocs(queryToExecute);
    const cabinetLogsArray = cabinetLogSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as Sample));
    setCabinetLogs(cabinetLogsArray);
  };

  useEffect(() => {
    fetchCabinetLogs();
  }, [userDealerId]);

  const columns = useMemo(
    () => [
      { accessorKey: 'itemNumber', header: '#', maxSize: 20,},
      { accessorKey: 'scanStatus', header: 'Status', maxSize: 25, },
      { accessorKey: 'orderNumber', header: 'Order', maxSize: 25 },
    ],
    []
  );

  const renderDetailPanel = ({ row, table }: { row: MRT_Row<Sample>; table: MRT_TableInstance<Sample>; }) => (
    <div style={{ padding: '10px' }}>
      <p>Order Number: {row.original.orderNumber}</p>
      <p>Description: {row.original.description}</p>
      <p>Quantity: {row.original.quantity}</p>
      <p>Checked In: {row.original.checkedInTimestamp?.toDate().toString()}</p>
      <p>Checked Out: {row.original.checkedOutTimestamp?.toDate().toString()}</p>
    </div>
  );

  useIonViewDidEnter(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    const updateTheme = (e: MediaQueryListEvent) => {
      document.body.classList.toggle('dark', e.matches);
    };

    document.body.classList.toggle('dark', matchMedia.matches);
    matchMedia.addEventListener('change', updateTheme);

    return () => {
      matchMedia.removeEventListener('change', updateTheme);
    };
  });

  const dealerIdsText = userData?.dealerIds || 'No Dealer IDs';

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    fetchCabinetLogs().then(() => {
      event.detail.complete();
    });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <IonPage>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Tab 1</IonTitle>
            </IonToolbar>
          </IonHeader>
          {developerMode && <ExploreContainer name={`Dealer IDs: ${dealerIdsText}`} />}
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div className="table-container">
                  <MaterialReactTable
                    columns={columns}
                    data={cabinetLogs}
                    renderDetailPanel={renderDetailPanel}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          {developerMode && (
            <>
              <p>{testVersion}</p>
              <IonInput value={dealerIdsText} placeholder="Dealer IDs" readonly />
            </>
          )}
        </IonContent>
      </IonPage>
    </ThemeProvider>
  );
};

export default Tab1;
